import React from 'react'
import './Input.css'

const Input = ({ name, placeholder, value, onChange, type, disable }) => {
    return (
        <div className='mt-3' >
            <p className='input-text' >{name}</p>
            <input disabled={disable ? disable : false} className='input-field' type={type ? type : 'text'} value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
        </div>
    )
}

export default Input