import React from 'react'
import { Helmet } from 'react-helmet'
import { About, Benefits, FAQ, Footer, Hero, Reviews, Steps } from './components'

const Home = () => {
  return (
    <div style={{ overflowX: 'hidden' }} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yummy Codes</title>
        <link rel="canonical" href="https://yummycodes.co.uk/" />
      </Helmet>

      <Hero />
      <About />
      <Benefits />
      <Steps />
      <FAQ />
      <Reviews />
      <Footer />

    </div>

  )
}

export default Home