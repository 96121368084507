import React from 'react'
import './Benefits.css'
import tick from '../../../../assests/tick.svg'

const Benefits = () => {
    return (
        <div className='benefits-container' >
            <div className="row">
                <div className='d-none d-md-inline  col-md-5 '>
                    <div className="benefits-left"/>

                </div>
                <div className="col-md-5">
                    <div className='benefits-right px-5 '>
                        <p className='benefits-heading mb-5 ps-0 ps-md-5 mt-2 mt-md-0  ' >Benefits of joining <br /> Yummy codes</p>

                        <div className='d-flex align-items-start ' >
                            <img src={tick} alt='tick' className='pt-2' />
                            <div className='ps-4' >
                                <p className='m-0 benefits-sub-heading '>Reduce dependency <br />
                                    on delivery platforms
                                </p>
                                <p className='mt-1 benefits-sub-text' >Market directly to customers who are local and, on the move.</p>
                            </div>
                        </div>

                        <div className='d-flex align-items-start mt-2' >
                            <img src={tick} alt='tick' className='pt-2' />
                            <div className='ps-4' >
                                <p className='m-0 benefits-sub-heading '>Drive direct traffic
                                </p>
                                <p className='mt-1 benefits-sub-text' >Connect with thousands of customers using the app locally by showcasing your offers.</p>
                            </div>
                        </div>




                        <div className='d-flex align-items-start mt-2' >
                            <img src={tick} alt='tick' className='pt-2' />
                            <div className='ps-4' >
                                <p className='m-0 benefits-sub-heading '>Increase your margins
                                </p>
                                <p className='mt-1 benefits-sub-text' >
                                    Further instore transactions, no delivery costs and reduced operational overheads.
                                </p>
                            </div>
                        </div>

                        <div className='d-flex align-items-start mt-2' >
                            <img src={tick} alt='tick' className='pt-2' />
                            <div className='ps-4' >
                                <p className='m-0 benefits-sub-heading '>Customer Insights
                                </p>
                                <p className='mt-1 benefits-sub-text' >Get to know what deals work the best for consumers in your area throughout the year and turn them into regulars.</p>
                            </div>
                        </div>






                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits