import React from 'react'
import './Reviews.css'
import person1 from '../../../../assests/person1.svg'
import person2 from '../../../../assests/person2.svg'
import person3 from '../../../../assests/person3.svg'
import person4 from '../../../../assests/person4.svg'
import person5 from '../../../../assests/person5.svg'
import person6 from '../../../../assests/person6.svg'
import person7 from '../../../../assests/person7.svg'
import person8 from '../../../../assests/person8.svg'
import person9 from '../../../../assests/person9.svg'
import person10 from '../../../../assests/person10.svg'
import person11 from '../../../../assests/person11.svg'
import { useHistory } from 'react-router-dom'

const data1 = [
    { id: 0, img: person1 },
    { id: 1, img: person2 },
    { id: 2, img: person3 },
    { id: 3, img: person4 },
    { id: 4, img: person5 },
    { id: 5, img: person6 },
]
const data2 = [
    { id: 6, img: person7 },
    { id: 7, img: person8 },
    { id: 8, img: person9 },
    { id: 9, img: person10 },
    { id: 10, img: person11 },
]

const Reviews = () => {
    const history = useHistory()



    return (
        <div className='review-container' >
            <div className="container">
                {/* <div className='reviews-top' >
                    {data1?.map((item) => (
                        <div key={item?.id} className='review-img-container' >
                            <img src={item?.img} alt={item?.id} />
                        </div>
                    ))}
                </div> */}

                {/* <div className='reviews-bottom' >
                    {data2?.map((item) => (
                        <div key={item?.id} className='review-img-container' >
                            <img src={item?.img} alt={item?.id} />
                        </div>
                    ))}
                </div> */}

                <div className='d-flex justify-content-center ' >
                    <p className='review-heading text-center ' >Increase your visibility, connect with your customers and grow
                        your business.</p>
                    {/* <div className='' >
                        <p className='review-text text-center' >  <q>I saw immediate results. I got more sells and visibility from I join <br /> the app.</q><strong> - John Smith</strong> </p>
                    </div> */}
                </div>

                <div className='d-flex justify-content-center my-3 my-md-5' >
                    <button onClick={() => history.push('/signup')} className='review-CTA mt-3 mt-lg-0'>
                        {/* <button className='hero-left-CTA'> */}
                        Let’s Get Started
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Reviews