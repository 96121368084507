import React from 'react'
import './About.css'
const About = () => {
    return (
        <div className='about-page p-4 p-md-0' >
            <div className='about-page-top' >
                <h2 className='about-page-heading' >
                    Connect with thousands of potential customers in your neighbourhood.
                </h2>
                <p className='about-page-para mt-4 ' >
                    Our platform enables your restaurant to market directly to customers whenever you wish to promote a new deal. Whether it’s a Buy One Get One deal or a percentage off, you can be sure it’ll be seen by deal hunting hungry customers.
                </p>
                <p className='about-page-para mt-4 ' >
                Yummy Codes platform allows you to create enticing deals that’ll drive local customers directly to your restaurant or takeaway.
                </p>
            </div>

            {/* <div className='about-page-bottom mt-5 mb-5 ' >
                <div className="row">
                    <div className="col-md-4">
                        <div className='about-page-bottom-views' >
                            <div className='about-page-bottom-img-view' >
                                <img className='about-page-bottom-img-view-img' src={noImage} alt="noimg" />
                            </div>
                            <p className='about-page-bottom-subheading' >Lorem ipsum dolor sit amet, consectetur.</p>
                            <p className='about-page-bottom-para' >Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='about-page-bottom-views' >
                            <div className='about-page-bottom-img-view' >
                                <img className='about-page-bottom-img-view-img' src={noImage} alt="noimg" />
                            </div>
                            <p className='about-page-bottom-subheading' >Lorem ipsum dolor sit amet, consectetur.</p>
                            <p className='about-page-bottom-para' >Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='about-page-bottom-views' >
                            <div className='about-page-bottom-img-view' >
                                <img className='about-page-bottom-img-view-img' src={noImage} alt="noimg" />
                            </div>
                            <p className='about-page-bottom-subheading' >Lorem ipsum dolor sit amet, consectetur.</p>
                            <p className='about-page-bottom-para' >Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>

                </div>
            </div> */}

        </div>

    )
}

export default About