import React from 'react'

const PrivacyComponent = () => {
    return (
        <div className='p-4' style={{ display: 'flex', flexDirection: 'column', gap: 10 ,padding:"10px"}}>



            <h1>PRIVACY POLICY</h1>

            <p> Effective date: 22nd September 2022
                This Privacy Statement explains how Yummy Codes Limited collect, control, process, and use information about you (which we call “personal data”).
                When we say “Yummy Codes”, “us”, “our”, or “we”, we mean Yummy Codes Limited. We provide platforms where we can list offers for vouchers and or deals.
                When we talk about the “Site”, we mean our platforms, which include our websites, mobile applications, communications we send or services we provide, social networking sites, or any other websites we offer that link to or reference this Privacy Statement.
                We routinely update this Privacy Statement to clarify our practices and to reflect new or different privacy practices, such as when we add new services, functionality, or features to the Site. If we make any material changes we will notify you, either by email (sent to the email address specified in your account), by means of notice on the Site or by using other methods. You can determine when this version of the Privacy Statement was adopted by referring to the “Effective Date” above.
                You understand that your continued use of the Site after we send a notice about our changes to the Privacy Statement means that the collection, control, processing, and use of your personal data is subject to the updated Privacy Statement.  If you object to any changes, you may modify your account settings or close your account as described below.
            </p>
            <h1>  Personal Data We Collect</h1>
            <p>   We will collect the following personal data when you interact with the Site:</p>

            <ul>
                <li>
                    Authentication and identification information (e.g. your name, email address, and password). This information is necessary to set up and log you into your account. We may not be able to provide our full range of account services to you;
                </li>
                <li>

                    Basic personal details (e.g. your name, email address, telephone number);
                </li>
                <li>

                    Contact details (e.g., your full name, telephone number, and email address). We may need some of this information to deliver products to you or your email address to send you vouchers.
                </li>
                <li>

                    Some information we collect is necessary for us to provide our services or meet our legal obligations. We will make this clear when we collect that information from you.
                </li>
            </ul>
            <p>
                We will also automatically collect personal data when you interact with the Site through your computer, mobile device, or other device. This personal data includes the following:
            </p>

            <ul>
                <li>
                    Analytics data (e.g., information about app downloads, app and web page histories), which may include data collected from cookies and other types of device identifiers;
                </li>
                <li>
                    Profile inputs (e.g., page and deal views on the site, click information and information about the website you clicked to our Site from). This may include data about your location. With respect to geolocation information collected from your mobile device, we will only collect this where you have provided consent;
                </li>
                <li>
                    Device details (e.g., MAC address, IP address, Bluetooth data and advertising identifiers).
                </li>
            </ul>

            <p>
                We also receive personal data and other online and offline information from third parties with whom we conduct business, such as merchants, co-marketers, distributors, resellers, and other companies or organizations with whom we enter into agreements to support our business and operations, including advertising partners and third-party data providers that provide us with supplemental or additional information about our customers (collectively “Business Partners”).
            </p>
            <p>The personal data we receive from Business Partners includes basic personal details, contact details, device details, profile inputs, as well as:
            </p>
            <ul>
                <li>Demographic information, (e.g., details about age brackets and educational background);</li>
                <li>Location data (e.g., information about postal code)</li>
            </ul>
            <p>
                We will only receive data from our Business Partners where they are legally permitted to share such data, and we will only process that data for the purposes described below. We use the data provided to better understand your preferences, deals that are relevant to you, and how our merchants are performing. By combining the data we collect directly from you with that received from third parties, we are able to provide you with a better, and more personalized, Yummy Codes experience
            </p>
            <p>We will collect the personal data described above at various stages in your relationship with us when you:
            </p>
            <ul>
                <li>Register, subscribe, authorise the transfer of, or create an account with us.</li>
                <li>Open or respond to emails or messages from us</li>
                <li>Provide information to enrol or participate in programs provided on behalf of, or together with Sellers and Business Partners, with your consent or as necessary to provide services you have requested</li>
                <li>Visit any page online that displays our ads or content</li>
                <li>Purchase products or services on or through the Site from Sellers;</li>
                <li>Connect, log-in, or link to the Site using social networking tools.</li>
            </ul>

            <p>We also create profiles about you based on the personal data you provide to us or that is collected about you, as described above and including personal data that we receive from Business Partners. We do this to market offers to you we think you would be interested in. The contents of that profile include:
            </p>
            <ul>
                <li>Account details. For example, we create a permanent URL to your account page which may include your name. We also generate tokens to remember your subscription and purchase histories, and any loyalty numbers you may have obtained</li>
                <li>Audience information. We create audiences based on parameters such as gender, age, and location (e.g., males aged 25-35 in your city), and if your personal data matches those audiences, you’ll be assigned to it. This is to help you receive relevant offers; and</li>
                <li>Activity information. Based on your interactions with communications, we’ll generate personal data about how many communications you like to receive, so that we don’t send you more than are useful to you</li>
            </ul>
            <h1>Your Choices</h1>
            <p>You can manage the types of personal data you provide to us and can limit how we communicate with you. At the same time, we think that the more you tell us about yourself and what you like, the more relevant and valuable your experience with our Site and services will be.
            </p>
            <p>You can manage your email, push notification, location information and subscription notification preferences by logging into your account through the Site or by adjusting the settings in our mobile application</p>
            <ul>
                <li>Push notifications and location information.  Your device and the Yummy Codes mobile app provide you with options to control push notifications and how and when we collect your geolocation. You can disallow our use of certain location data through your device or browser settings, for example, by disabling “Location” services for the Yummy Codes application in Apple’s iOS and Android privacy settings, or by disabling “Location” services for your device;</li>
                <li>Subscriptions.  You can also manage your subscriptions by following subscription management instructions contained in any commercial emails that we send you. You may choose to subscribe to some types of messages, and may choose not to subscribe to, or to unsubscribe from, others. You may update your subscription preferences at any time. Even if you decide not to subscribe to, or to unsubscribe, from promotional email messages, we may still need to contact you with important transactional information related to your account and your purchases from Sellers. For example, even if you have unsubscribed from our promotional email messages or push notifications, we will still send you confirmations when you make purchases on the Site</li>
                <li>Cookies. You can manage how your browser handles cookies. You may also manage how your mobile device and mobile browser share information on and about your devices with us, as well as how your mobile browser handles cookies. Please refer to our Cookies Policy for more information</li>
                <li>Objecting to uses of data. In certain circumstances, you have the right to object to the use of your personal data. In particular, you can object to our use of personal data where we use such data to meet our own interests in running our business (as described under ‘How We Use Information’ below and including use of your data for marketing purposes and management of our business needs). If you object to our use of your data, we will assess the objection and cease processing that data upon confirmation of a valid request. For more information, see ‘Your Rights’ below</li>
            </ul>
            <h1>Your Rights</h1>
            <ul>
                <li>Keeping your personal data current helps ensure that we can respect your preferences and offer you the goods and services that are most relevant to you.</li>
                <li>In accordance with applicable law, you may (i) request access to any personal data we hold about you; (ii) request that it be updated, rectified, deleted or blocked (iii) request that we delete personal data we hold about you; (iv) request that we restrict our processing of your personal data; and (v) request that we provide you or a third party with a copy of certain personal data about you (that is referred to as the right of  “data portability”). You can also object to any of our uses of your personal data described in this policy, including our marketing activities. You may also revoke your consent to the processing of your personal data, to the extent consent was required and provided by you.</li>
            </ul>

            <h1>How We Use Information
            </h1>

            <p>We control and process the personal data you provide to us, which we collect from other sources, and which we generate to</p>
            <ul>
                <li>Create your account when you sign up and log you in, which is necessary for us to provide our services to you in accordance with the Terms of Use</li>
                <li>Operate, maintain, and improve the Site by analysing how you and our other customers use and interact with it. This is to meet our legitimate business interests in providing the Site and ensuring that it provides the best experience for our customers</li>
                <li>Validate, facilitate, and prevent fraudulent purchases. This may include processing orders for vouchers and other goods and services, payment verification, and verifying that vouchers redeemed by customers are valid. This is necessary to meet our contractual commitments to you set out in the Terms of Use</li>
                <li>Carry-out marketing, which may involve</li>
                <ul>
                    <li>Establishing and analysing individual and group profiles and customer behaviour, in order to determine your or others interest in certain types of offers, products, and services. We do this by analysing your interactions with the Site and your other personal data (including personal data received from Business Partners) to determine what your interests are, and what sorts of products and services people with similar interests also buy, which helps us understand what products and services you may be interested in viewing. This is to meet our legitimate interests in understanding the types of products and services our customers are interested in, and to provide the most relevant products to you and our other customers</li>
                    <li>Showing relevant offers and advertising. We will use the profiles described above to create advertising for our products that will be displayed on relevant third-party sites. We do this to meet our legitimate interests in showing you products which may be relevant to you, and you can opt out of seeing these types of ads as described in our Cookies Policy</li>
                    <li>Sending you relevant direct marketing messages and other communications via email or push notifications on mobile devices, including, with your consent, using your location data to notify you of tailored location-based deals. We will either send these messages on the basis that you have consented to receiving them or, where permitted by applicable law, to meet our legitimate interests in showing you which of our products and services are relevant to you; and</li>
                    <li>Analyzing advertising effectiveness, which may involve analyzing the advertising campaigns our customers choose to interact with most often. This is to meet our legitimate interests in understanding which types of advertising campaigns are more or less effective than others</li>

                </ul>

                <li>Answer your questions and respond to your requests, for example in the context of customer service. This is to meet our contractual commitments to you in the Terms of Use where these questions or requests are part of the purchase process or to comply with legal obligations (such as allowing you to exercise your rights as described above), and in other cases to meet our legitimate interests in providing a good service to our customers</li>
                <li>Send you reminders, technical notices, updates, security alerts, support and administrative messages, service bulletins, and requested information, including other Sellers. This is to meet our legitimate interests in managing our relationship with you effectively</li>
                <li>Administer rewards, surveys, sweepstakes, contests, or other promotional activities or events, in order to meet our contractual commitments to you set out in the terms and conditions of those promotional events</li>
                <li>Manage our everyday business needs, such as administration of the Site, forum management, fulfilment, analytics, fraud prevention, and enforcement of our corporate reporting obligations and Terms of Use or to comply with the law; and</li>
                <li>Comply with our legal obligations, resolve disputes, and enforce our agreements. We do this where necessary to comply with legal obligations to which we are subject, or to meet our legitimate interests in enforcing our legal rights and resolving disputes or verifying payments and preventing fraud</li>

            </ul>

            <h1>When and Why, We Disclose Personal Data</h1>
            <p>We share your personal data as follows:
            </p>
            <ul>
                <li>with your consent</li>
                <li>with unaffiliated third-party Sellers and Business Partners, so they can sell, deliver, and provide the products or services purchased to you (e.g., to deliver products to you. We share personal data with unaffiliated third-party Sellers and Business Partners in order to meet our contractual obligations to you, and they are not permitted to use your personal data in any way other than for selling, delivering, and/or providing the products or services purchased by you</li>
                <li>with third parties that provide tools and services to help us better understand your deal preferences, so that we can show you advertisements for Yummy Codes vouchers, goods, or services on third party websites which are more suited to your interests and tastes. Those third parties may also use your personal data to match you with their existing customer base. You can stop our use of these tools in the ways described in our Cookies Policy</li>
                <li>to report or collect on debts owed to Sellers or other Business Partners</li>
                <li>as necessary to perform contractual obligations towards you with Sellers or Business Partners to the extent you have redeemed a Yummy Codes voucher, goods, or services offered by them or participated in an offer, rewards, contest or other activity or program sponsored or offered through us or the Sellers</li>
                <li>to a subsequent owner, co-owner, or operator of one or more of the Sites or any portion or operation related to part of one or more of the Sites</li>
                <li>to comply with legal orders and government requests, or as needed to support auditing, compliance, and corporate governance functions, where this is necessary to comply with these legal obligations</li>
                <li>to combat fraud or criminal activity, and to protect our rights or Sellers rights, or Business Partners, and users, or as part of legal proceedings affecting us, as it is in our legitimate interests to prevent fraud and protect these rights; or</li>
                <li>any legal process, including to law enforcement agencies, regulators, and courts, to the extent this is necessary to comply with such legal obligations</li>

            </ul>

            <p>We encourage our unaffiliated third-party Sellers and Business Partners to adopt and post privacy policies. However, while we share personal data with Sellers and Business Partners only for the above-mentioned purposes, their subsequent processing and use of personal data obtained through us is governed by their own privacy policies and practices and is not under our control (except for the use and processing by Sellers and Business Partners providing services to us, as described above). Where possible, we contractually restrict how our Sellers and Business Partners, including merchants, use your personal data and aim to ensure they do not use it for any purposes which are incompatible with those set out in this privacy statement.
            </p>

            <h1>Security of Personal Data
            </h1>
            <p>We have implemented an information security program that contains administrative, technical and physical controls that are designed to safeguard your personal data, including industry-standard encryption technology. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.
            </p>

            <h1>Retention of Personal Data
            </h1>
            <p>We will retain your personal data for as long as your account is active or as needed to provide you services. If you close your account, we will retain your personal data for a period where it is necessary to continue operating our business effectively, to maintain a record of your transactions for financial reporting purposes or fraud prevention purposes until these purposes no longer exist, and to retain as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements</p>

            <h1>Privacy Practices of Third Parties
            </h1>
            <p>This Privacy Statement only addresses the collection, processing and use (including disclosure) of personal data by us through your interaction with the Site. Other websites that may be accessible through links from the Site may have their own privacy statements and personal data collection, processing, use, and disclosure practices. We encourage you to familiarise yourself with the privacy statements provided by these other parties prior to providing them with information or taking advantage of a sponsored offer or promotion</p>

            <h1>Remove Account
            </h1>
            <ul>
                <li>Navigate to Account page</li>
                <li>Access the About section</li>
                <li>Select the option to delete the account</li>
            </ul>
            
            <h1>Contact Us
            </h1>

            <p>If you have any questions or comments about our privacy practices or this Privacy Statement, please contact our Data Protection Officer at dpo@yummycodes.co.uk </p>
       
       
        </div>

    )
}
export default PrivacyComponent