import React from 'react'
import './Footer.css'
import facebooklogo from '../../../../assests/facebooklogo.svg'
import linkedinlogo from '../../../../assests/linkedinlogo.svg'
import twitterlogo from '../../../../assests/twitterlogo.svg'
import instagramlogo from '../../../../assests/instagramlogo.svg'

const Footer = () => {
    return (
        // <div className='container footer my-4' >
        // <p className='footer-text' >  © Yummy Codes</p>
        //     <div>
        // <img className='me-3' src={facebooklogo} alt="facebooklogo" />
        // <img className='me-3' src={linkedinlogo} alt="linkedinlogo" />
        // <img  className='me-3' src={instagramlogo} alt="instagramlogo" />
        // <img className='me-3' src={twitterlogo} alt="twitterlogo" />
        //     </div>
        // </div>
        <div className="row d-flex align-items-center px-5 py-4 ">
            <div className="col-md-6 ">
                <p className='footer-text d-flex justify-content-md-start justify-content-center m-0' >  © Yummy Codes {new Date().getFullYear()}</p>
            </div>
            <div className="col-md-6">
                <div className=' d-flex justify-content-md-end justify-content-center  '>
                    <a href="https://www.facebook.com/YummyCodesUK/" target={"_blank"} ><img style={{ cursor: 'pointer' }} className='me-3' src={facebooklogo} alt="facebooklogo" /></a>
                    <a href="https://www.linkedin.com/company/yummy-codes-app/" target={"_blank"} >   <img style={{ cursor: 'pointer' }} className='me-3' src={linkedinlogo} alt="linkedinlogo" /></a>
                    <a href="https://www.instagram.com/yummycodesuk/" target={"_blank"} >  <img style={{ cursor: 'pointer' }} className='me-3' src={instagramlogo} alt="instagramlogo" /></a>
                    <a href="https://twitter.com/CodesYummy" target={"_blank"} >  <img style={{ cursor: 'pointer' }} className='me-3' src={twitterlogo} alt="twitterlogo" /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer