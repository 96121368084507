import React, { useRef, useState } from 'react'
import './FAQ.css'

const faqs = [
    {
        question: "Will I get support?",
        answer:
            "You can be assured an experienced member of our support team is available 24/7/365 whenever you need some advice.",
    },
    {
        question: "How long to start selling?",
        answer:
            "Once you’ve signed up, your restaurant will be live in a few days. Get started with the process by signing up. We’re super excited to hear from you!",
    },
    {
        question: "Are there any costs involved?",
        answer:
            "Currently, there’s no fee for joining Yummy Codes. Take advantage and sign up today. Increase your sales and promote your brand.",
    },
    {
        question: "What other restaurants are on the platform?",
        answer:
            "We have a diverse selection of restaurants in your area. These range from small independent restaurants to national chains.",
    },
];
const FAQ = () => {
    const [clicked, setClicked] = useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };


    return (
        <div className='faq-container my-3 my-md-5 ' >
            <div className='faqs  my-5  '>
                <h2 className='text-center faq-heading mb-3 ' >Frequently Asked Questions</h2>

                {faqs.map((faq, index) => (
                    <AccordionItem active={clicked === index}
                        onToggle={() => handleToggle(index)} key={index} faq={faq} />
                ))}
            </div>
        </div >
    )
}

export default FAQ

const AccordionItem = ({ faq, onToggle, active }) => {
    const { question, answer } = faq;

    const contentEl = useRef();

    return (
        <div className={`accordion_item ${active ? "active" : ""}`}>
            <button className="button" onClick={onToggle}>
                {question}
                <span className="control">{active ? "—" : "+"} </span>
            </button>
            <div
                ref={contentEl}
                className="answer_wrapper"
                style={
                    active
                        ? { height: contentEl.current.scrollHeight }
                        : { height: "0px" }
                }
            >
                <div className="answer">{answer}</div>
            </div>
        </div>
    );
};