import React from 'react'
import './Hero.css'
import HeroRight from '../../../../assests/HeroRight.png'
import heroright2 from '../../../../assests/heroRight.jpeg'
import logo from '../../../../assests/logo.svg'
import androidButton from '../../../../assests/androidButton.svg'
import iosButton from '../../../../assests/iosButton.svg'
import avatarImg from '../../../../assests/avatarImg.svg'
import { useHistory } from 'react-router-dom'

const Hero = () => {
    const history = useHistory()
    return (
        <>
            <div className="row">
                <div className="col-md-6 p-0">
                    <div className='hero-left' >
                        <div className="d-flex  align-items-center h-100">
                            <div className="hero-left-content p-md-5 p-4">
                                <img className='hero-left-logo' src={logo} alt="logo" />
                                <h1 className='hero-left-heading mt-5 ' >
                                    Reach new customers <br /> with Yummy Codes
                                </h1>
                                <p className='hero-left-para my-5 ' >

                                    Boost your visibility and reach customers <br /> by partnering with us today.</p>
                                <div className='d-flex flex-wrap ' >

                                    <button onClick={() => history.push('/signup')} className='hero-left-CTA '>
                                        JOIN US TODAY
                                    </button>

                                    <a target={"_blank"} className="mt-2 mt-lg-0 ms-1 ms-md-0 ms-lg-3  " href="https://portal.yummycodes.co.uk/">
                                        <button className='hero-left-CTA '>
                                            Login As Merchant
                                        </button>
                                    </a>
                                </div>

                                {/* <div className="mt-5 mt-md-0 d-flex d-md-inline flex-column flex-md-row "> */}
                                {/* <div className=""> */}
                                {/* <img className='me-0 me-sm-3 mt-3  mt-lg-0' style={{ objectFit: 'cover', cursor: 'pointer' }} src={iosButton} alt="ios-buuton" /> */}
                                {/* <img className='mt-3  mt-lg-0 android-buuton' clas style={{ objectFit: 'cover', cursor: 'pointer' }} src={androidButton} alt="android-buuton" /> */}
                                {/* </div> */}

                                <div style={{ marginTop: '100px' }} className='d-flex align-items-center' >
                                    <div className='hero-left-avatar' >
                                        <img className='hero-left-avatar-img' src={avatarImg} alt="avatar" />
                                    </div>
                                    <div className='ms-3' >
                                        <p className='hero-left-review-text' >  <q>I saw immediate results. I got more sells and <br />visibility from I join the app.</q><strong> - John Smith</strong> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-none d-md-inline col-md-6 p-0">
                    <div className='hero-right' >
                        <img className='hero-right-img' src={heroright2} alt="heroright" />
                    </div>
                </div>
            </div>
        </>

    )
}

export default Hero