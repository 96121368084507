import React, { useState } from 'react'
import './Signup.css'
import Modal from 'react-modal';
import logo from '../../assests/darkLogo.svg'
import noImage from '../../assests/noImage.svg'
import submitting from '../../assests/submitting.svg'
import cross from '../../assests/cross.svg'
import { Input } from '../../components';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';


const Signup = () => {
  const history = useHistory()
  const [showVerification, setShowVerification] = useState(true)
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState("")
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState()
  const [address, setAddress] = useState("")
  const [street, setStreet] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("UK")
  const [zip, setZip] = useState("")

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [streetError, setStreetError] = useState(false)
  const [cityError, setCityError] = useState(false)
  const [countryError, setCountryError] = useState(false)
  const [zipError, setZipError] = useState(false)


  const submit = () => {

    let notanumber = /^([^0-9]*)$/
    let reg = /^\s*$/
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (reg.test(name)) {
      toast.error('Name is required.')
      setNameError(true)
      setTimeout(() => {
        setNameError(false)
      }, 3000);
      return;
    }

    if (emailReg.test(email) === false) {
      toast.error('Email is required.')
      setEmailError(true)
      setTimeout(() => {
        setEmailError(false)
      }, 3000);
      return;
    }

    if (notanumber.test(phone) || phone == '') {
      toast.error('Phone number is required.')
      setPhoneError(true)
      setTimeout(() => {
        setPhoneError(false)
      }, 3000)
      return;
    }
    // if (address == '') {
    //   toast.error('Address is required.')

    //   setAddressError(true)
    //   setTimeout(() => {
    //     setAddressError(false)
    //   }, 3000)
    //   return;
    // }


    if (street == '') {
      toast.error('Street is required.')

      setStreetError(true)
      setTimeout(() => {
        setStreetError(false)
      }, 3000)
      return;
    }
    if (city == '') {
      toast.error('City is required.')

      setCityError(true)
      setTimeout(() => {
        setCityError(false)
      }, 3000)
      return;
    }

    if (country == '') {
      toast.error('Country is required.')

      setCountryError(true)
      setTimeout(() => {
        setCountryError(false)
      }, 3000)
      return;
    }
    if (zip == '') {
      toast.error('Zip / Postal Code is required.')

      setZipError(true)
      setTimeout(() => {
        setZipError(false)
      }, 3000)
      return;
    }



    else {
      setLoading(true)
      name.replace(/ +/g, "")
      email.replace(/ +/g, "")
      phone.replace(/ +/g, "")

      addMerchant({
        name: name,
        email: email,
        phone: phone,
        street: street,
        address: street,
        zipCode: zip,
        city: city,
        country: country
      }).then((res) => {
        console.log(res, 'resresres')
        setLoading(false)
        setShowVerification(false)
        setName("")
        setEmail("")
        setPhone("")
        setCity("")
        setZip("")
        setCountry("")
        setStreet("")
      }).catch(e => {
        if (e == 'Error: Request failed with status code 406') {
          alert('Merchant Already exist')
          setLoading(false)
          return;
        }
        else {
          toast.error(e?.response?.data?.message)

          // alert('Something went wrong Try Again')
          setLoading(false)
        }
      }
      )
    }


  }

  const addMerchant = (body) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios({
          method: 'POST',
          url: 'https://yummycodesapi.co.uk/api/merchant/self',
          data: body,
        })
        resolve(data)
      } catch (error) {
        console.log(error, 'error')
        reject(error)
      }
    })
  }



  const verify = () => {
    setShowVerification(false)
  }


  return (
    <div className='signup-container' >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yummy Codes</title>
        <link rel="canonical" href="https://yummycodes.co.uk/" />
      </Helmet>
      <div className='signup-shade' />
      <Modal
        ariaHideApp={false}
        isOpen={true}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        // className="modalStyle"
        className={`${showVerification ? "Modal" : "Modal2"}`}
        overlayClassName="Overlay"
      // style={customStyles}
      >


        {showVerification ? <>
          <div className='d-flex justify-content-center border-bottom py-3' >
            <img src={logo} alt="logo" />
          </div>
          <div className="mt-3">
            <div className='signup-noimage-view' >
              {/* <div className='signup-noimage-img-view' >
                <img className='signup-noimage-img-view-img' src={noImage} alt="noimg" />
              </div> */}
              <p className='signup-noimage-subheading m-0 mt-3' >Let’s start selling!</p>
              <p className='signup-noimage-para m-0' >Hi, signing up is easy it won’t take long.</p>
            </div>
          </div>

          <div className='m-4' >

            <Input value={name} onChange={setName} name={"Full name *"} />
            <Input value={email} onChange={setEmail} name={"email address *"} />
            <Input value={phone} onChange={setPhone} type={"number"} name={"Phone Number"} />

            {/* <Input value={address} onChange={setAddress} name={"Address"} /> */}
            <Input value={street} onChange={setStreet} name={"Street"} />
            <Input value={city} onChange={setCity} name={"City"} />
            <Input value={zip} onChange={setZip} name={"Zip / Postcode"} />
            <Input disable value={country} onChange={setCountry} name={"Country"} />


            <div className='my-4' >
              <button disabled={loading} onClick={submit} className='next-button' > {loading ? <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
                : 'Sign Up'}</button>
            </div>


            {/* <p className='text-center' >Already have an account? <strong>Log in</strong> </p> */}
            {/* <hr className='hr-line' /> */}
            <p className='text-center' >By Signing Up you agree to our <span style={{ cursor: 'pointer' }} className='text-decoration-underline' >Privacy Policy</span></p>


          </div>
        </> :

          <div className="mt-3">
            <div className='d-flex justify-content-end align-items-start pe-4 pt-3 ' >
              <img onClick={() => history.push('/')} style={{ cursor: 'pointer' }} src={cross} alt="noimg" />
            </div>
            <div className='signup-noimage-view' >
              <div className='signup-noimage-img-view' >
                <img className='signup-noimage-img-view-img' src={submitting} alt="noimg" />
              </div>
              <p className='signup-noimage-subheading m-0 mt-3' >Thank you!</p>
              {/* <p className='signup-noimage-subheading m-0' >We have received your request and will respond soon.</p> */}
              <p className='signup-noimage-para m-0' >We will contact you in the next few days about getting<br /> set up and ready to launch.</p>
            </div>
          </div>
        }
      </Modal>
    </div>
  )
}

export default Signup