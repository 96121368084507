import React from 'react'
import './Steps.css'
import stepLogo from '../../../../assests/stepLogo.svg'
const Steps = () => {
    return (
        <div className='steps-container' >
            <div className='steps-subcontainer' >
                <p className='steps-heading' > Start Selling in 3 Easy Steps!</p>

                <div>
                    <div className='steps-bottom ' >
                        <div className="row">
                            <div className="col-md-4">
                                <div className='steps-bottom-views' >
                                    {/* <div className='steps-bottom-img-view' >
                                        <img className='steps-bottom-img-view-img' src={noImage} alt="noimg" />
                                    </div> */}

                                    <p className='m-0 steps-bottom-subheading' >Join Us</p>
                                    <p className='m-0 steps-bottom-para' ><strong> Create an account </strong> with Yummy Codes today and get up and running in no time. Our team will reach out and help guide you through the next steps.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='steps-bottom-views' >
                                    {/* <div className='steps-bottom-img-view' >
                                        <img className='steps-bottom-img-view-img' src={noImage} alt="noimg" />
                                    </div> */}
                                    <p className='m-0 steps-bottom-subheading' >Create an offer</p>
                                    <p className='m-0 steps-bottom-para' >You can offer a range of promotions, such as:
                                        Buy one, get one free, £5 off your first order or Free drink with orders over £15 and many other types of promotions.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='steps-bottom-views' >
                                    {/* <div className='steps-bottom-img-view' >
                                        <img className='steps-bottom-img-view-img' src={noImage} alt="noimg" />
                                    </div> */}
                                    <p className='m-0 steps-bottom-subheading' >Grow Your Business!</p>
                                    <p className='m-0 steps-bottom-para' >We help you to grow your business by enabling thousands of people to find your restaurant and by making it super easy for your customers to order more.</p>
                                </div>
                            </div>

                        </div>


                        <div className="dashed-border" />
                        <div className="rounder-corner1" />
                        <div className="rounder-corner2" />
                        <div className="rounder-corner3">
                            <img style={{ height: '50px' }} src={stepLogo} />
                        </div>

                    </div>


                </div>


            </div>
        </div>
    )
}

export default Steps