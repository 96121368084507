import { Home, Privacy, Signup, Support } from "./pages";
import {
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <>
   
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/support" component={Support} />
      </Switch>

    </>
  );
}

export default App;
